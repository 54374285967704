import strings from "../../i18n/i18nStrings";
import { NAVIGATION_IDS } from "../common/constants/navigation";
import styles from "./Footer.module.scss";

const Footer = () => (
    <div className={styles.footer}>
        <p>
            <a href={NAVIGATION_IDS.ABOUT}>{strings.common.pages.about}</a>
        </p>
        <p>
            <a href={NAVIGATION_IDS.FAQ}>{strings.common.pages.faq}</a>
        </p>
        <p>
            <a href={NAVIGATION_IDS.LEGAL}>{strings.common.pages.legal}</a>
        </p>
        {/* <p>
            <a href={NAVIGATION_IDS.BLOG}>{strings.common.pages.blog}</a>
        </p> */}
        <p className={styles.copyright}>{strings.common.copyright}</p>
        <p className={styles.copyrightMobile}>{strings.common.copyrightMobile}</p>
    </div>
);

export default Footer;