const strings = {
    faq: {
        generalQuestions: {
            title: 'General Questions',
            questions: {
                q1: {
                    question: 'What is LampPost?',
                    answer: "LampPost is a community engagement platform designed to help people discover, organize, and participate in local events. Our mission is to strengthen communities by making it easier to connect with what's happening nearby.",
                },
                q2: {
                    question: 'How does LampPost work?',
                    answer: "LampPost is all about events. Users can easily create, share, and join local events, from small gatherings to large community activities. The platform makes discovering events seamless, helping people stay engaged and connected with what’s happening around them.",
                },
            }
        },
        accountAndProfile: {
            title: 'Account and Profile',
            questions: {
                q1: {
                    question: 'How do I create an account on LampPost?',
                    answer: 'Signing up is easy! Click the "Login" button in the header and use your email to create an account. Afterward you’re ready to start discovering and joining events!'
                },
                q2: {
                    question: 'How can I edit my profile information?',
                    answer: 'Log in to your account and use the menu to select the "My Settings" page. Here, you can update your personal details to better connect with your local event community.',
                },
            }
        },
        reportingIssues: {
            title: 'Reporting Issues',
            questions: {
                q1: {
                    question: 'How do I report an issue with an event?',
                    answer: 'If you notice a problem with an event, please email support@lammpost.app with the title of "Report Event" and details of the issue (such as the issue type and a brief description). Our team will review it to ensure events remain safe and enjoyable for everyone.'
                },
                q2: {
                    question: 'What types of issues can I report?',
                    answer: 'You can report issues such as incorrect event details, inappropriate content, spam events, or safety concerns.',
                },
            }
        },
        events: {
            title: 'Events',
            questions: {
                q1: {
                    question: 'How do I find events on LampPost?',
                    answer: 'Local events are front and center on the homepage! Simply open LampPost to browse upcoming events by category, date, or location.',
                },
                q2: {
                    question: 'How do I create an event?',
                    answer: 'On our homepage, click "Create Event" and then enter key details like the title, date, time, location, and description, then submit. Once approved, your event will be visible to the community.',
                },
                q3: {
                    question: 'Can I edit or cancel an event I created?',
                    answer: 'Yes, filter events by "My Created Events" and click the edit or delete buttons on the event details page. From there you can make the necessary changes.',
                },
            }
        },
        communityGuidelines: {
            title: 'Community Guidelines',
            questions: {
                q1: {
                    question: 'What are the community guidelines for using LampPost?',
                    answer: 'LampPost is a space for discovering and sharing local events. We ask users to keep event listings accurate, appropriate, and in line with local laws. Events that contain misleading information, offensive content, or violate community standards may be removed.',
                },
                q2: {
                    question: 'How can I report inappropriate content?',
                    answer: 'If you find an event that violates our guidelines, email support@lamppost.app with details about the issue. Our team will review and take appropriate action.',
                },
            }
        },
        technicalSupport: {
            title: 'Technical Support',
            questions: {
                q1: {
                    question: 'The app/website isn`t working properly. What should I do?',
                    answer: 'First, try refreshing the page or restarting the app. If the issue continues, check the app for any known issues or email support@lamppost.app for further assistance.',
                },
            }
        },
        privacyAndSecurity: {
            title: 'Privacy and Security',
            questions: {
                q1: {
                    question: 'How does LampPost protect my privacy?',
                    answer: 'We take your privacy seriously and use industry-standard measures to protect your data. For more details, please review our Privacy Policy.',
                },
                q2: {
                    question: 'Can I delete my account?',
                    answer: 'Yes, you can delete your account by going to your profile settings and selecting the "Delete Account" option. Please note that this action is irreversible, so proceed carefully.',
                },
            }
        },
        miscellaneous: {
            title: 'Miscellaneous',
            questions: {
                q1: {
                    question: 'How do I contact LampPost support?',
                    answer: 'You can contact our support team by emailing support@lamppost.app.',
                },
                q2: {
                    question: 'Can businesses or organizations join LampPost?',
                    answer: 'Yes, businesses can create profiles, post events, and engage with the community. For more info, email support@lamppost.app.',
                },
                q3: {
                    question: 'How do I give feedback or suggestions?',
                    answer: 'We’d love to hear from you! Feel free to email support@lamppost.app with your feedback and suggestions.',
                },
            }
        }
    }
};

export default strings;