import Accordion from "../../components/common/accordion/Accordion";
import strings from "../../i18n/i18nStrings";
import PageLayout from "../common/PageLayout";
import styles from "./FaqPage.module.scss";

const FaqPage = () => (
    <PageLayout>
        <div className={styles.faqContainer}>
            {
                Object.values(strings.faq).map((faqInfo) => (
                    <div>
                        <p className={styles.faqHeader}>{faqInfo.title}</p>
                        <div className={styles.faqQAContainer}>
                            {Object.values(faqInfo.questions).map(faqQuestion => (
                                <Accordion
                                    collapsedContent={faqQuestion.question}
                                    expandedContent={
                                        <span>{faqQuestion.answer}</span>
                                    }
                                />
                            ))}
                        </div>
                    </div>
                ))
            }
        </div>
    </PageLayout>
);

export default FaqPage;