import strings from "../../i18n/i18nStrings";
import PageLayout from "../common/PageLayout";
import styles from "./AboutPage.module.scss";
import qotsaImage from "../../assets/images/qotsa-concert.png";

const AboutPage = () => (
    <PageLayout>
        <div className={styles.aboutUsContainer}>
            <div>
                <h1 className={styles.aboutUsTitle}>{strings.aboutUs.sections.welcome.title}</h1>
            </div>
            <div className={styles.ourMissionContainer}>
                <div className={styles.ourMissionInfo}>
                    <p className={styles.aboutUsHeader}>{strings.aboutUs.sections.ourMission.title}</p>
                    <p>{strings.aboutUs.sections.ourMission.paragraph}</p>
                </div>
                <img
                    className={styles.imageContainer}
                    src={qotsaImage}
                    alt="Concert"
                />
            </div>
            <div>
                <p className={styles.aboutUsHeader}>{strings.aboutUs.sections.whatWeDo.title}</p>
                <div className={styles.whatWeDoContainer}>
                    {
                        Object.values(strings.aboutUs.sections.whatWeDo.sections).map((sectionInfo) => (
                            <>
                                <div />
                                <div className={styles.whatWeDoInfo}>
                                    <div className={styles.titleContainer}>
                                        <h2>{sectionInfo.title}</h2>
                                    </div>
                                    <p>{sectionInfo.description}</p>
                                </div>
                                <div />
                            </>
                        ))
                    }
                </div>
            </div>
            <div>
                <p className={styles.aboutUsHeader}>{strings.aboutUs.sections.ourValues.title}</p>
                <div className={styles.ourValuesContainer}>
                    {
                        Object.values(strings.aboutUs.sections.ourValues.sections).map((sectionInfo) => (
                            <div>
                                <div className={styles.titleContainer}>
                                    <h3>{sectionInfo.title}</h3>
                                </div>
                                <p>{sectionInfo.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div>
                <div className={styles.joinUsContainer}>
                    <div className={styles.sidebar} />
                    <div className={styles.info}>
                        <p className={styles.aboutUsHeader}>{strings.aboutUs.sections.joinUs.title}</p>
                        <div>
                            <p>{strings.aboutUs.sections.joinUs.paragraphs.paragraph1}</p>
                            <p>{strings.aboutUs.sections.joinUs.paragraphs.paragraph2}</p>
                        </div>
                    </div>
                    <div className={styles.sidebar} />
                </div>
            </div>
            <div>
                <p className={styles.aboutUsHeader}>{strings.aboutUs.sections.getInTouch.title}</p>
                <div className={styles.getInTouchContainer}>
                    <div>
                        <p>{strings.aboutUs.sections.getInTouch.paragraphs.paragraph1}</p>
                        <p>{strings.aboutUs.sections.getInTouch.paragraphs.paragraph2}</p>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>
);

export default AboutPage;