const strings = {
    aboutUs: {
        title: 'About Us',
        sections: {
            welcome: {
                title: 'Bringing Communities Together, One Event at a Time',
            },
            ourMission: {
                title: 'Our Mission',
                paragraph: 'We’re on a mission to bring people together through local events and shared experiences. By making it easier to discover, organize, and participate in community happenings, we help create connections that make neighborhoods more vibrant and welcoming.',
            },
            whatWeDo: {
                title: 'What We Do',
                sections: {
                    find: {
                        title: '🎉 Find Local Events',
                        description: "Stay in the loop with everything happening in your community! Whether it's a farmers market, a neighborhood festival, a fitness class, or a local workshop, LampPost makes discovering events easy and fun.",
                    },
                    share: {
                        title: '📢 Share Your Events',
                        description: "Hosting something special? From garage sales and volunteer cleanups to live music and pop-up markets, easily create and promote your event to reach the right audience.",
                    },
                    update: {
                        title: '🔔 Stay Updated',
                        description: "RSVP to events, set reminders, and get notified about new happenings so you never miss out.",
                    },
                    explore: {
                        title: '🗂 Explore a Variety of Events',
                        description: "Find events tailored to your interests, whether it's arts and culture, outdoor adventures, networking meetups, or family-friendly activities.",
                    },
                },
            },
            ourValues: {
                title: 'Our Values',
                sections: {
                    community: {
                        title: "🤝 Community First",
                        description: "We believe strong communities make life better. LampPost is here to bring people together, creating a space where neighbors can connect and make great things happen."
                    },
                    connections: {
                        title: "🔗 Meaningful Connections",
                        description: "The best communities are built on relationships. Our platform is designed to make it easier for people to interact, engage, and build lasting connections through local events."
                    },
                    locals: {
                        title: "🚀 Empowering Locals",
                        description: "Whether you’re organizing an event or looking for ways to get involved, we give you the tools to take an active role in your community."
                    },
                    userFriendly: {
                        title: "✅ Simple & User-Friendly",
                        description: "Finding and sharing local events should be easy. We’ve designed LampPost to be intuitive, so you can focus on what matters—getting involved."
                    },
                }
            },
            joinUs: {
                title: 'Join Us',
                paragraphs: {
                    paragraph1: "We're looking for passionate people to help us build the future of community connections.",
                    paragraph2: "Check out our open positions below!",
                }
            },
            getInTouch: {
                title: 'Get in Touch',
                paragraphs: {
                    paragraph1: 'We’d love to hear from you! Whether you have questions, ideas, or feedback, we’re here to listen.',
                    paragraph2: 'Drop us a message at support@lamppost.app'
                }
            },
        }
    }
};

export default strings;