import { useState } from "react";
import PageLayout from "../common/PageLayout";
import Modal from "../../ui/modal/Modal";
import strings from "../../i18n/i18nStrings";
import styles from "./LegalPage.module.scss";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";


const LegalPage = () => {
    const [modalText, setModalText] = useState(null);

    const getModalContents = () => (
        <div className={styles.modalContainer}>
            <p className={styles.modalHeader}>{modalText.title}</p>
            <p className={styles.effectiveDate}>{modalText.effectiveDate}</p>
            {
                Object.values(modalText.sections).map((modalSection: any) => (
                    <div className={modalSection.title.length ? styles.sectionContainer : ''}>
                        <p className={styles.sectionHeader}>{modalSection.title}</p>
                        {Object.values(modalSection.paragraphs).map((modalParagraph: string) => (
                            <p className={modalSection.title.length ? styles.sectionInfo : ''}>{modalParagraph}</p>
                        ))}
                    </div>
                ))
            }
        </div>
    )

    return (
        <>
            <PageLayout>
                <>
                    <div className={styles.legalContainer}>
                        <div className={`${styles.modalLinks} ${modalText ? styles.modalOpen : ''}`}>
                            <p className={styles.legalHeader}>{strings.legal.legal}</p>
                            <p>{strings.legal.description}</p>
                            <div className={styles.legalLink}>
                                <span onClick={() => setModalText(strings.legal.privacyPolicy)}>{strings.legal.privacyPolicy.title}</span>
                            </div>
                            <div className={styles.legalLink}>
                                <span onClick={() => setModalText(strings.legal.termsOfService)}>{strings.legal.termsOfService.title}</span>
                            </div>
                            <div className={styles.legalLink}>
                                <span onClick={() => setModalText(strings.legal.cookiePolicy)}>{strings.legal.cookiePolicy.title}</span>
                            </div>
                        </div>
                        {
                            modalText && (
                                <div className={styles.modalMobileContents}>
                                    <Button
                                        onPress={() => setModalText(null)}
                                        roleVariant={BUTTON_VARIANTS.GHOST}
                                    >
                                        <span>{`<`} {strings.common.back}</span>
                                    </Button>
                                    {getModalContents()}
                                </div>
                            )
                        }
                    </div>
                </>
            </PageLayout>
            {
                modalText && (
                    <>
                        <Modal
                            isOpen={!!modalText}
                            onClose={() => setModalText(null)}
                        >
                            {getModalContents()}
                        </Modal>
                    </>
                )
            }
        </>
    )
}

export default LegalPage;