import { useEventsContext } from "../../../contexts/EventsContext";
import { EventType } from "../../../types/EventType";
import styles from "./EventDisplayListItem.module.scss";
import { useAppContext } from "../../../contexts/AppContext";
import { ReactComponent as LogoIcon } from '../../../assets/icons/LogoIcon.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/StarIcon.svg';
import { getEventDates } from "../../../utils/getEventDates";

const EventDisplayListItem = ({
    event,
    loadRef
}: {
    event: EventType
    loadRef?: (node: any) => void
}) => {
    const { userInfo } = useAppContext();

    const {
        onSelectEvent,
        selectedEvent,
        viewingEvent
    } = useEventsContext();

    return (
        <div
            id={`${event._id}`}
            key={event._id}
            className={`
                ${styles.eventContainer}
                ${event._id === selectedEvent ? styles.selectedEvent : ''}
                ${viewingEvent ? styles.viewingEvent : ''}
            `}
            onClick={() => onSelectEvent(event._id)}
            ref={loadRef}
        >
            {
                userInfo?.events?.attending.find(eventId => eventId === event._id) && (
                    <div className={styles.attendingIcon}>
                        <StarIcon />
                    </div>
                )
            }
            <div className={styles.imageContainer}>
                {
                    event.images.length ? (
                        <div
                            className={styles.uploadedImageContainer}
                            style={{ backgroundImage: `url(${event.images[0]})` }}
                        >
                            <div className={styles.imageBlurContainer}>
                                <img
                                    src={event.images[0]}
                                    className={styles.image}
                                    alt="User Upload"
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={`${styles.image} ${styles.logo}`}>
                            <LogoIcon />
                        </div>
                    )
                }
            </div>
            <div className={styles.eventInfo}>
                <div className={styles.title}>
                    {event.name}
                </div>
                <div className={styles.location}>
                    {event.location.name}
                </div>
                <div className={styles.time}>
                    {getEventDates(event.startDateTime, event.endDateTime)}
                </div>
            </div>
        </div>
    )
}

export default EventDisplayListItem;