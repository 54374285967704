import strings from "../../../i18n/i18nStrings";
import styles from "./EmptyDisplay.module.scss";
import { ReactComponent as LogoIcon } from '../../../assets/icons/LogoIcon.svg';
import { LAMP_POST_COLORS } from "../../../ui/constants";

const LOGO_SIZE = 256;

const EmptyDisplay = () => (
    <div>
        <div className={styles.emptyContainer}>
            <LogoIcon
                className={styles.logoImage}
                fill={LAMP_POST_COLORS.amber}
                height={LOGO_SIZE}
                width={LOGO_SIZE}
            />
            <div>
                <h4>{strings.eventDetailsScreen.emptyEvent.description1}</h4>
            </div>
            <div>
                {strings.eventDetailsScreen.emptyEvent.description2}
            </div>
        </div>
    </div>
)

export default EmptyDisplay;