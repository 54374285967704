const strings = {
    legal: {
        legal: 'Your Privacy, Your Rights, Our Commitment',
        description: 'We value your trust and are committed to keeping your data safe while fostering a respectful and inclusive community. Below, you’ll find details about our Privacy Policy, Terms of Service, and Community Guidelines.',
        privacyPolicy: {
            title: 'Privacy Policy for LampPost',
            effectiveDate: 'Effective Date: 2/2/2025',
            sections: {
                section1: {
                    title: '1. Introduction',
                    paragraphs: {
                        paragraph1: 'LampPost (“we,” “our,” or “us”) values your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.lamppost.app] or our mobile application (the “Site”). Please read this policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Site.'
                    }
                },
                section2: {
                    title: '2. Information We Collect',
                    paragraphs: {
                        paragraph1: 'Personal Data: We may collect personally identifiable information such as your name, email address, and phone number when you register for an account, subscribe to our newsletter, or contact us.',
                        paragraph2: 'Event Information: When you create or participate in events, we collect information related to those events, such as event details, locations, and attendees.',
                        paragraph3: 'Usage Data: We collect information about your interactions with the Site, including IP addresses, browser types, referring/exit pages, and operating systems.',
                        paragraph4: 'Cookies and Tracking Technologies: We use cookies and similar tracking technologies to track activity on our Site and hold certain information.'
                    }
                },
                section3: {
                    title: '3. How We Use Your Information',
                    paragraphs: {
                        paragraph1: 'We use the information we collect to:',
                        paragraph2: 'Provide, operate, and maintain our Site',
                        paragraph3: 'Improve, personalize, and expand our Site',
                        paragraph4: 'Understand and analyze how you use our Site',
                        paragraph5: 'Develop new products, services, features, and functionality',
                        paragraph6: 'Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Site',
                        paragraph7: 'Send you emails',
                        paragraph8: 'Find and prevent fraud',
                    }
                },
                section4: {
                    title: '4. Sharing Your Information',
                    paragraphs: {
                        paragraph1: 'We may share your information with third parties for the following purposes:',
                        paragraph2: 'With service providers to perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance',
                        paragraph3: 'If required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency)',
                        paragraph4: 'In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company',
                        paragraph5: 'With your consent'
                    }
                },
                section5: {
                    title: '5. Security of Your Information',
                    paragraphs: {
                        paragraph1: 'We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable.'
                    }
                },
                section6: {
                    title: '6. Your Data Protection Rights',
                    paragraphs: {
                        paragraph1: 'Depending on your location, you may have the following rights regarding your personal data:',
                        paragraph2: 'The right to access – You have the right to request copies of your personal data.',
                        paragraph3: 'The right to rectification – You have the right to request that we correct any information you believe is inaccurate.',
                        paragraph4: 'The right to erasure – You have the right to request that we erase your personal data, under certain conditions.',
                        paragraph5: 'The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.',
                        paragraph6: 'The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.',
                        paragraph7: 'The right to data portability – You have the right to request that we transfer the data we have collected to another organization, or directly to you, under certain conditions.',
                    }
                },
                section7: {
                    title: '7. Changes to This Privacy Policy',
                    paragraphs: {
                        paragraph1: 'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Site prior to the change becoming effective and update the “Effective Date” at the top of this Privacy Policy.'
                    }
                },
                section8: {
                    title: '8. Contact Us',
                    paragraphs: {
                        paragraph1: 'If you have any questions about this Privacy Policy, please contact us at support@lamppost.app.'
                    }
                },
            }
        },
        termsOfService: {
            title: 'Terms of Service for LampPost',
            effectiveDate: 'Effective Date: 2/2/2025',
            sections: {
                section1: {
                    title: '1. Acceptance of Terms',
                    paragraphs: {
                        paragraph1: 'By accessing or using the LampPost website or mobile application (the “Site”), you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree with these terms, please do not use the Site.'
                    }
                },
                section2: {
                    title: '2. User Accounts',
                    paragraphs: {
                        paragraph1: 'To access certain features of the Site, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and for all activities that occur under your account.'
                    }
                },
                section3: {
                    title: '3. Use of the Site',
                    paragraphs: {
                        paragraph1: 'You agree to use the Site only for lawful purposes and in accordance with these Terms of Service. You agree not to use the Site:',
                        paragraph2: 'In any way that violates any applicable federal, state, local, or international law or regulation',
                        paragraph3: 'To impersonate or attempt to impersonate LampPost, a LampPost employee, another user, or any other person or entity',
                        paragraph4: 'To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Site, or which, as determined by us, may harm LampPost or users of the Site or expose them to liability',
                    }
                },
                section4: {
                    title: '4. Event Creation and Participation',
                    paragraphs: {
                        paragraph1: 'Users may create and participate in local events. LampPost is not responsible for the content or conduct of any event. By creating or participating in an event, you agree to comply with all applicable laws and regulations.'
                    }
                },
                section5: {
                    title: '5. Intellectual Property Rights',
                    paragraphs: {
                        paragraph1: 'The Site and its original content, features, and functionality are and will remain the exclusive property of LampPost and its licensors. The Site is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of LampPost.'
                    }
                },
                section6: {
                    title: '6. Termination',
                    paragraphs: {
                        paragraph1: 'We may terminate or suspend your account and bar access to the Site immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.'
                    }
                },
                section7: {
                    title: '7. Limitation of Liability',
                    paragraphs: {
                        paragraph1: 'In no event shall LampPost, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Site; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Site; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Site by any third party; and/or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Site, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.'
                    }
                },
                section8: {
                    title: '8. Governing Law',
                    paragraphs: {
                        paragraph1: 'These Terms shall be governed and construed in accordance with the laws of the State of California, without regard to its conflict of law provisions.'
                    }
                },
                section9: {
                    title: '9. Changes to Terms of Service',
                    paragraphs: {
                        paragraph1: 'We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days` notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.'
                    }
                },
                section10: {
                    title: '10. Contact Us',
                    paragraphs: {
                        paragraph1: 'If you have any questions about these Terms, please contact us at support@lamppost.app.'
                    }
                },
            }
        },
        cookiePolicy: {
            title: 'Cookies Policy for LampPost',
            effectiveDate: 'Effective Date: 2/2/2025',
            sections: {
                section1: {
                    title: '',
                    paragraphs: {
                        paragraph1: 'This Cookies Policy explains what Cookies are and how LampPost uses them. You should read this policy so you can understand what type of cookies LampPost uses, or the information LampPost collects using cookies and how that information is used. This Cookies Policy has been created with the help of the Cookies Policy Generator.',
                        paragraph2: 'Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.',
                        paragraph3: 'We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the cookies LampPost uses.',
                    }
                },
                section2: {
                    title: 'Interpretation',
                    paragraphs: {
                        paragraph1: 'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
                    }
                },
                section3: {
                    title: 'Definitions',
                    paragraphs: {
                        paragraph1: 'For the purposes of this Cookies Policy:',
                        paragraph2: 'Company (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to LampPost.',
                        paragraph3: 'Cookies means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.',
                        paragraph4: 'Website refers to LampPost, accessible from www.lamppost.app',
                        paragraph5: 'You means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.',
                    }
                },
                section4: {
                    title: 'Type of Cookies We Use',
                    paragraphs: {
                        paragraph1: 'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.',
                        paragraph2: 'We use both session and persistent Cookies for the purposes set out below:',
                        paragraph3: 'Necessary / Essential Cookies',
                        paragraph4: 'Type: Session Cookies',
                        paragraph5: 'Administered by: Us',
                        paragraph6: 'Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.',
                        paragraph7: 'Functionality Cookies',
                        paragraph8: 'Type: Persistent Cookies',
                        paragraph9: 'Administered by: Us',
                        paragraph10: 'Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
                    }
                },
                section5: {
                    title: 'Your Choices Regarding Cookies',
                    paragraphs: {
                        paragraph1: 'If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.',
                        paragraph2: 'If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.',
                        paragraph3: 'If You`d like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.',
                        paragraph4: 'For the Chrome web browser, please visit this page from Google: https://support.google.com/accounts/answer/32050',
                        paragraph5: 'For the Internet Explorer web browser, please visit this page from Microsoft: http://support.microsoft.com/kb/278835',
                        paragraph6: 'For the Firefox web browser, please visit this page from Mozilla: https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored',
                        paragraph7: 'For the Safari web browser, please visit this page from Apple: https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac',
                        paragraph8: 'For any other web browser, please visit your web browser`s official web pages.',
                    }
                },
                section6: {
                    title: 'More Information about Cookies',
                    paragraphs: {
                        paragraph1: 'You can learn more about cookies here: All About Cookies by TermsFeed.',
                    }
                },
                section7: {
                    title: 'Contact Us',
                    paragraphs: {
                        paragraph1: 'If you have any questions about this Cookies Policy, You can contact us by email at support@lamppost.app',
                    }
                },
            }
        }
    }
}

export default strings;