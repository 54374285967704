import { useEffect, useState } from "react";
import styles from "./PhotoRoll.module.scss";

const PhotoRoll = ({
    photoUrls,
    isCircular = false,
}: {
    photoUrls?: string[]
    isCircular?: boolean;
}) => {
    const [selectedPhoto, setSelectedPhoto] = useState(photoUrls?.length ? photoUrls[0] : '');

    useEffect(() => {
        setSelectedPhoto(photoUrls?.length ? photoUrls[0] : '');
    }, [photoUrls]);

    const getSubImages = () => {
        return photoUrls.map((photoUrl, idx) => (
            <div
                key={idx}
                onClick={() => setSelectedPhoto(photoUrl)}
            >
                <img
                    src={photoUrl}
                    className={`
                        ${styles.imageContainer}
                        ${isCircular ? styles.circularContainer : styles.squareContainer}
                    `}
                    alt="User Upload"
                />
            </div>
        ))
    }

    return (
        <div className={styles.photoRollContainer}>
            <div
                className={styles.selectedImageContainer}
                style={{ backgroundImage: `url(${selectedPhoto})` }}
            >
                <div className={styles.imageBlurContainer}>
                    <img
                        src={selectedPhoto}
                        className={`
                            ${styles.selectedImage}
                            ${isCircular ? styles.circularContainer : styles.squareContainer}
                        `}
                        alt="User Main Upload"
                    />
                    {
                        photoUrls?.length && photoUrls.length > 1 && (
                            <>
                                <div className={styles.subImagesContainer}>
                                    {getSubImages()}
                                </div>
                                <div className={styles.circleContainer}>
                                    {photoUrls.map(() => (
                                        <div className={styles.circle}>
                                            <div className={styles.innerCircle} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            {
                photoUrls?.length && photoUrls.length > 1 && (
                    <div className={styles.subImagesContainerMobile}>
                        {getSubImages()}
                    </div>
                )
            }
        </div>
    )
}

export default PhotoRoll;