import styles from './Button.module.scss';

export const BUTTON_VARIANTS = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    GHOST: 'ghost',
    DESTRUCTIVE: 'destructive',
    CTA: 'cta'
}

export const BUTTON_SIZE = {
    SM: 'sm',
    MD: 'md',
    LG: 'lg'
}

const Button = ({
    onPress,
    roleVariant = BUTTON_VARIANTS.PRIMARY,
    sizeVariant = BUTTON_SIZE.MD,
    disabled = false,
    text,
    children
}: {
    text?: string;
    onPress: () => void;
    roleVariant?: string;
    sizeVariant?: string;
    disabled?: boolean;
    children?: React.ReactElement;
}) => (
    <button
        onClick={onPress}
        className={`
            ${styles.button}
            ${styles[`${roleVariant}Button`]}
            ${styles[`${sizeVariant}Button`]}
            ${disabled ? styles.disabled : ''}
        `}
        disabled={disabled}
    >
        {children || text}
    </button>
);

export default Button;