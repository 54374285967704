import { ReactNode, useState } from "react"
import { ReactComponent as ChevronIcon } from '../../../assets/icons/ChevronIcon.svg';
import styles from "./Accordion.module.scss";
import { LAMP_POST_COLORS } from "../../../ui/constants";

const Accordion = ({
    collapsedContent,
    expandedContent
}: {
    collapsedContent: string;
    expandedContent: ReactNode;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => setIsOpen(prevState => !prevState);

    return (
        <>
            <div
                className={styles.collapsedContainer}
                onClick={toggleIsOpen}
            >
                <span>{collapsedContent}</span>
                <ChevronIcon
                    width={30}
                    fill={LAMP_POST_COLORS.oliveGreen}
                    className={isOpen ? styles.chevronOpen : ''}
                />
            </div>
            {
                isOpen && (
                    <div className={styles.expandedContainer}>
                        {expandedContent}
                    </div>
                )
            }
        </>
    )
}

export default Accordion;